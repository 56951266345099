<template>
  <n-grid
    :cols="2"
    :x-gap="12"
    :y-gap="12"
    item-responsive
    responsive="screen"
  >
    <n-gi
      v-motion-slide-visible-once-bottom
      span="2 s:2 m:1"
    >
      <div class="card">
        <NH1>Повышение квалификации</NH1>
        <n-scrollbar style="max-height: 400px">
          <n-timeline size="large">
            <n-timeline-item
              v-for="(item, index) in coursesHistory"
              :key="index"
              :title="item.title"
              :content="item.content"
              :time="item.time"
              :line-type="item.lineType"
              :type="item.type"
            />
          </n-timeline>
        </n-scrollbar>
      </div>
    </n-gi>

    <n-gi
      v-motion-slide-visible-once-bottom
      span="2 s:2 m:1"
    >
      <div class="card">
        <NH1>Электронные сертификаты</NH1>
        <n-timeline size="large">
          <n-timeline-item
            v-for="(item, index) in eventsHistory"
            :key="index"
            :line-type="item.lineType"
            :title="item.title"
            :time="item.time"
          />
        </n-timeline>
      </div>
    </n-gi>
  </n-grid>
</template>
<script setup lang="ts">
  import { NGrid, NGi, NH1, NTimeline, NTimelineItem, NScrollbar } from 'naive-ui';
  import type { TimelineItemProps } from 'naive-ui';
  import { ref } from 'vue';

  const coursesHistory = ref<TimelineItemProps[]>([
    {
      title: 'Курс',
      content: 'Nuxt.js',
      time: 'В процессе обучения',
      lineType: 'dashed',
      type: 'success',
    },
    {
      title: 'Курс',
      content: 'Vue-разработчик',
      time: '2020',
      lineType: 'dashed',
    },
    {
      title: 'Интенсив "быстрый старт в веб-разработке"',
      content: 'Академия вёрстки Артема Исламова',
      time: '2018',
      lineType: 'dashed',
    },
    {
      title: 'Видеокурс "Базовый JS"',
      content: 'Академия вёрстки Артема Исламова',
      time: '2018',
      lineType: 'dashed',
    },

    {
      title: 'Челлендж "Прорыв"',
      content: 'Академия вёрстки Артема Исламова',
      time: '2018',
      lineType: 'dashed',
    },
    {
      title: 'Интенсив "Где здесь деньги?"',
      content: 'Академия вёрстки Артема Исламова',
      time: '2018',
      lineType: 'dashed',
    },
    {
      title: 'Интенсив по Frontend',
      content: 'Школа веб-разработки WebCademy',
      time: '2018',
      lineType: 'dashed',
    },
    {
      title: 'Интенсив по Backend',
      content: 'Школа веб-разработки WebCademy',
      time: '2018',
      lineType: 'dashed',
    },
    {
      title: 'Интенсив по базовому JS',
      content: 'Школа веб-разработки WebCademy',
      time: '2018',
      lineType: 'dashed',
    },
    {
      title: 'Курс "Веб-разработчик"',
      content: 'Академия вёрстки Артема Исламова',
      time: '2017',
      lineType: 'dashed',
    },
    {
      title: 'Профессия рабочего, должность служащего',
      content: 'ГБПОУ ЯНАО НКПИИТ, Оператор электронно-вычислительных машин - 3 разряд',
      time: '2017',
    },
  ]);

  const eventsHistory = ref<TimelineItemProps[]>([
    {
      lineType: 'dashed',
      title: 'Хакатон от "Сбертройка"',
      time: '2021',
    },
    {
      lineType: 'dashed',
      title: 'Хакатон от "Цифровой прорыв"',
      time: '2021',
    },
    {
      lineType: 'dashed',
      title: 'FREE FRONT GEEKBRAINS',
      time: '2019',
    },
    {
      lineType: 'dashed',
      title: 'HUAWEI HONOR CUP',
      time: '2019',
    },
    {
      lineType: 'dashed',
      title: 'WEBDEV SUMMIT',
      time: '2019',
    },
    {
      lineType: 'dashed',
      title: 'Основы GEEKBRAINS',
      time: '2019',
    },
    {
      title: 'Веб-разработчик',
      time: '2017',
    },
  ]);
</script>
