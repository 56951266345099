<template>
  <section ref="projectsHeader">
    <n-space
      v-motion-slide-visible-once-bottom
      justify="space-between"
      align="center"
    >
      <NH1>Проекты</NH1>

      <n-button
        tertiary
        round
        @click="toggleShowFull()"
      >
        {{ showFull ? 'Скрыть' : `Показать все (${projects.length})` }}
      </n-button>
    </n-space>

    <n-grid
      item-responsive
      responsive="screen"
      :cols="3"
      :x-gap="12"
      :y-gap="12"
    >
      <n-gi
        v-motion-slide-visible-once-bottom
        v-for="project in projectsToShow"
        :key="project.title"
        span="3 s:3 m:1"
      >
        <div
          class="card"
          @click="openProject(project)"
        >
          <div class="image br-05">
            <app-image
              :path="project.image"
              styles="height: 100%;width: 100%;object-fit: cover;"
            />
          </div>
          <NH3>{{ project.title }}</NH3>

          <n-space size="small">
            <n-tag
              size="small"
              round
              v-for="tag in project.stack"
              :key="tag"
            >
              {{ tag }}
            </n-tag>
          </n-space>
          <div class="date">
            <n-tag
              size="small"
              round
              :bordered="false"
            >
              {{ project.date }}
            </n-tag>
          </div>
        </div>
      </n-gi>
    </n-grid>

    <n-modal
      v-model:show="showModal"
      class="custom-card"
      preset="card"
      :style="{ width: '800px' }"
      :bordered="false"
      size="huge"
      :segmented="{ content: 'soft', footer: 'soft' }"
    >
      <template #header>
        <n-space size="small">
          <n-tag
            size="small"
            round
            v-for="tag in currentProject.tags"
            :key="tag"
          >
            {{ tag }}
          </n-tag>
        </n-space>
        {{ currentProject.title }}
      </template>

      <n-image
        class="br-05"
        width="100%"
        height="100"
        object-fit="cover"
        :src="imagePath"
      />

      <n-space
        size="small"
        class="mt-05 mb-05"
      >
        <n-tag
          size="small"
          round
          v-for="tag in currentProject.stack"
          :key="tag"
        >
          {{ tag }}
        </n-tag>
      </n-space>

      <n-text
        v-for="(text, index) in currentProject.description"
        :key="index"
      >
        {{ text }}
        <br />
      </n-text>
      <template #footer>
        <n-space size="small">
          <n-tag
            v-for="link in currentProject.links"
            :key="link"
            :href="link"
            target="_blank"
            @click="openLink(link)"
            checkable
            round
            :bordered="false"
            size="medium"
          >
            {{ link }}
            <template #icon>
              <n-icon>
                <LayersLinked />
              </n-icon>
            </template>
          </n-tag>
        </n-space>
      </template>
    </n-modal>
  </section>
</template>
<script setup lang="ts">
  import { NSpace, NGrid, NGi, NH1, NText, NH3, NTag, NButton, NModal, NImage, NIcon } from 'naive-ui';
  import AppImage from './AppImage.vue';
  import { computed, ref } from 'vue';
  import { useDebounceFn, useImage } from '@vueuse/core';
  import { LayersLinked } from '@vicons/tabler';
  import type { ComputedRef } from 'vue';

  const projectsHeader = ref<HTMLElement | null>(null);
  const showFull = ref<boolean>(false);
  const showModal = ref<boolean>(false);
  const currentProject = ref<any>(null);

  const projects: ComputedRef = computed(() => {
    return [
      {
        title: 'Invaiti',
        date: 'Актуальный проект',
        tags: ['Production'],
        image: 'invaiti.webp',
        stack: ['VUE.JS 3 + TYPESCRIPT', 'NAIVE UI', 'APPWRITE', 'PINIA COLADA'],
        description: [
          'В свободное от работы время разрабатываю свой онлайн-сервис.',

          'Invaiti предоставляет возможность легко и быстро создать элегантные цифровые приглашения на различные события, такие как дни рождения, свадьбы, корпоративные вечеринки и многое другое. Вы можете выбрать из различных шаблонов, настроить дизайн и добавить информацию о мероприятии, такую как дата, время, место проведения и контактные данные.',

          'Кроме того, вы можете отслеживать статус отклика гостей и взаимодействовать с ними через платформу. Это удобный способ организовать свое мероприятие и быть в курсе, кто из гостей примет участие.',
        ],
        links: [
          'https://dev.invaiti.app/',
          'https://invaiti.app/',
          'https://vk.com/invaiti_app',
          'https://t.me/invaiti_app',
        ],
      },
      {
        title: 'CommuneX',
        date: 'Актуальный проект',
        tags: ['Production'],
        image: 'communex.webp',
        stack: ['VUE.JS 3 + TYPESCRIPT', 'IONIC', 'CAPACITOR'],
        description: [
          'Communex централизует внутренние коммуникации, улучшает обмен информацией и способствует сотрудничеству внутри организации, преодолевая замкнутость, чтобы максимально повысить производительность и оптимизировать рабочие процессы.',
        ],
        links: ['https://www.intrakommuna.de/', 'https://communex.app/en/'],
      },

      {
        title: 'CHS',
        date: '07.2023',
        tags: ['SPA', 'Production'],
        image: 'chs.webp',
        stack: ['HTML+CSS', 'VUE.JS 3 + TYPESCRIPT', 'NAIVE UI', 'SUPABASE'],
        description: [
          'Информационная система для организации деятельности сотрудников компании по поставке компьютерного оборудования и программного обеспечения. Дипломный проект.',
        ],
        links: ['https://github.com/MaximSedov/chs'],
      },
      {
        title: 'CYBERNUR - турниры по FIFA',
        date: '06.2021',
        tags: ['SPA', 'Production'],
        image: 'cybernur-landing.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI', 'FIREBASE'],
        description: [
          'Ко мне обратился Илья, он является организатором турниров по игре FIFA, в связи с этим появилась необходимость в сервисе, который поможет организовать все внутренние процессы по проведению турниров.',
          'Первой задачей было создание промо-страницы, на которой кратко отображается информация о компании, история создания, текущий турнир, инструкция, о том, как принять участие, а так же партнеры и спонсоры турниров.',
          'Второй задачей было создание онлайн платформы, в которой участники могут подавать заявку на участие, отслеживать результаты турниров и рейтинг игроков.',
        ],
        links: ['https://vk.com/@sedovmax-case-cybernur', 'https://cybernur.ru/', 'https://cybernur.online/'],
      },
      {
        title: 'ИТ Продукт',
        date: '11.2021',
        tags: ['Landing', 'Practice'],
        image: 'product.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI', 'XICONS'],
        description: [
          'Практический проект представляет собой верстку лэндинга - презентацию разработанного мобильного приложения.',
        ],
        links: ['sdvmxm.ru/product', 'github.com/MaximSedov/product'],
      },

      {
        title: 'Аукцион рыбок',
        date: '10.2021',
        tags: ['SPA', 'Practice'],
        image: 'pisces.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI'],
        description: ['Практический проект представляет собой платформу аукциона экзотических рыбок.'],
        links: ['sdvmxm.ru/pisces'],
      },
      {
        title: 'Волонтерское движение',
        date: '11.2021',
        tags: ['SPA', 'Practice'],
        image: 'foodsharing.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI', 'xicons'],
        description: [
          'Проект в рамках хакатона.',
          'Практический проект представляет собой платформу для работы волонтеров фудшеринга.',
          'АНО «Фудшеринг» — сервис по распределению продуктов питания с истекающим сроком годности — привлекает волонтеров для своей деятельности. Сейчас в базе организации порядка 2 тысяч волонтеров. Они регулярно забирают продукты из магазинов, пекарен или кафе, а затем раздают своим подопечным (постоянным получателям продовольственной помощи).',
          'При каждой передаче еды волонтеры заполняют и подписывают соответствующий акт. Для более эффективной работы АНО «Фудшеринг» требуется веб-приложение, которое позволит рекрутировать и обучать волонтеров, автоматизировать их каждодневную работу с актами и поможет вести мониторинг волонтерской деятельности.',
        ],

        links: ['sdvmxm.ru/foodsharing'],
      },
      {
        title: 'Экомаршруты',
        date: '09.2021',
        tags: ['SPA', 'Production'],
        image: 'map.webp',
        stack: ['HTML+CSS', 'VUE', 'PRIME-VUE', 'OpenStreetMap'],
        description: [
          'Командная работа в рамках ХАКАТОНА.',
          'Мы разработали ПО для построения оптимального экологичного маршрута на разных типах транспорта.',
          'Разработка является как дополнением к существующему приложению "Московский транспорт", так и самостоятельным кроссплатформенным ПО в котором пользователи могут построить динамический маршрут для прогулок или поездок на велосипеде и самокате.',
          'Технологический стек: vue.js, python, OpenStreetMap, Rest API, GraphHopper, JSON, Docker.',
        ],
        links: ['https://youtu.be/PQVbtcgLim8'],
      },
      {
        title: 'Redrowant',
        date: '05.2021',
        tags: ['Landing', 'Test-Task'],
        image: 'redrowant.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI'],
        description: ['Лендинг предоставленного макета в рамках тестового задания по вакансии.'],
        links: ['https://maximsedov.github.io/redrowant/'],
      },
      {
        title: 'GS',
        date: '05.2021',
        tags: ['SPA', 'Test-Task'],
        image: 'gs.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI'],
        description: ['Тренажер памяти в рамках тестового задания по вакансии.'],
        links: ['https://maximsedov.github.io/gs/', 'https://github.com/MaximSedov/gs'],
      },
      {
        title: 'Oxem',
        date: '06.2021',
        tags: ['SPA', 'Test-Task'],
        image: 'oxem.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI'],
        description: [
          'Подключение к базе и отрисовка таблицы с поиском, пагинацией и добавлением новых строк в рамках тестового задания по вакансии.',
        ],
        links: ['https://maximsedov.github.io/oxem/', 'https://github.com/MaximSedov/oxem'],
      },
      {
        title: 'WEBSTR',
        date: '07.2021',
        tags: ['SPA', 'Test-Task'],
        image: 'webstr.webp',
        stack: ['HTML+CSS', 'VUE', 'ELEMENT-UI'],
        description: ['To-do лист с сохранением в localstorage в рамках тестового задания по вакансии.'],
        links: ['https://maximsedov.github.io/webstr/', 'https://github.com/MaximSedov/webstr'],
      },
      {
        title: 'Аренда жилья',
        date: '06.2019',
        tags: ['Practice'],
        image: 'booking.webp',
        stack: ['HTML+CSS'],
        description: ['В рамках практики.'],
        links: [],
      },
      {
        title: 'АИС по созданию курсов',
        date: '06.2019',
        tags: ['Multi-page', 'Production'],
        image: 'ais.webp',
        stack: ['HTML+CSS', 'PHP', 'Font-Awesome'],
        description: ['Автоматизированная информационная система по созданию курсов. Дипломный проект.'],
        links: [],
      },
      {
        title: 'Магазин электроники',

        date: '05.2019',
        tags: ['Practice'],
        image: 'techstore.webp',
        stack: ['HTML+CSS', 'JS'],
        description: ['В рамках практики.'],
        links: [],
      },
      {
        title: 'Пародия на твиттер',

        date: '07.2018',
        tags: ['SPA', 'Practice'],
        image: 'switter.webp',
        stack: ['HTML+CSS', 'PHP'],
        description: [
          'Реализован функционал публикации сообщений на общей странице при авторизации под личным аккаунтом. Подсчет общего количества сообщений, авторство и дата/время над сообщением, уведомления об успешной/безуспешной работе.',
        ],

        links: [],
      },
    ];
  });

  const projectsToShow: ComputedRef = computed(() => projects.value.slice(0, showFull.value ? undefined : 3));

  const debouncedFn = useDebounceFn(() => {
    if (projectsHeader.value) {
      projectsHeader.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 50);

  const toggleShowFull = () => {
    showFull.value = !showFull.value;
    debouncedFn();
  };

  const openProject = async (project: any) => {
    currentProject.value = project;
    await prepareImage(project.image);
    showModal.value = true;
  };
  const imagePath = ref('');
  const {} = useImage({ src: imagePath.value });
  const prepareImage = async (path: string) => {
    try {
      const images = import.meta.glob('../assets/**/*.{jpg,jpeg,png,gif,svg,webp}');
      const imageModule = images[`../assets/${path}`];
      if (imageModule) {
        const module = (await imageModule()) as { default: string };
        imagePath.value = module.default;
      } else {
        console.error('Image not found:', path);
      }
    } catch (error) {
      console.error('Error loading image:', error);
    }
  };

  const openLink = (link: string) => {
    window.open(link, '_blank');
  };
</script>

<style scoped>
  .card {
    .date {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
    }
  }
  .card:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(88, 88, 88, 0.24),
      0 3px 6px 0 rgba(88, 88, 88, 0.18),
      0 5px 12px 4px rgba(88, 88, 88, 0.12);
    transition: box-shadow 0.2s linear;
    .image {
      opacity: 1;
    }
  }
  .image {
    height: 160px;
    overflow: hidden;
    opacity: 0.9;
  }

  .n-image {
    width: 100%;
  }
</style>
