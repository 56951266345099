<template>
  <n-grid
    item-responsive
    responsive="screen"
    :cols="3"
    :x-gap="12"
    :y-gap="12"
  >
    <n-gi
      v-motion-slide-visible-once-bottom
      span="3 s:3 m:1"
    >
      <div class="card">
        <n-space justify="space-between">
          <NH3>Онлайн игра</NH3>

          <n-space justify="end">
            <n-button
              size="small"
              circle
              @click="openLink('https://vk.com/hurtfun2')"
            >
              <template #icon>
                <n-icon><ExternalLink /></n-icon>
              </template>
            </n-button>

            <n-button
              size="small"
              circle
              @click="openLink('https://vk.com/rivalrust')"
            >
              <template #icon>
                <n-icon><ExternalLink /></n-icon>
              </template>
            </n-button>
          </n-space>
        </n-space>
        <NText>
          Был основателем небольших проектов по онлайн-играм
          <n-gradient-text type="success">Hurtworld</n-gradient-text>
          и
          <n-gradient-text type="danger">Rust</n-gradient-text>
          , участвовал в разработке конфигурации игровых серверов и магазинов предметов
        </NText>
      </div>
    </n-gi>
    <n-gi
      v-motion-slide-visible-once-bottom
      span="3 s:3 m:1"
    >
      <div class="card">
        <NH3>Дипломная работа колледжа</NH3>
        <NText>
          В рамках дипломной работы колледжа разработал автоматизированную информационную систему по созданию курсов для
          студентов
        </NText>

        <n-space
          class="mt-05"
          size="small"
        >
          <n-tag
            type="success"
            size="small"
            round
          >
            HTML
          </n-tag>
          <n-tag
            type="warning"
            size="small"
            round
          >
            JS
          </n-tag>
          <n-tag
            type="error"
            size="small"
            round
          >
            PHP
          </n-tag>
        </n-space>
      </div>
    </n-gi>
    <n-gi
      v-motion-slide-visible-once-bottom
      span="3 s:3 m:1"
    >
      <div class="card">
        <NH3>Дипломная работа университета</NH3>
        <NText>
          В рамках дипломной работы университета разработал информационную систему компании по поставке компьютерного
          оборудования и программного обеспечения
        </NText>

        <n-space
          class="mt-05"
          size="small"
        >
          <n-tag
            type="success"
            size="small"
            round
          >
            VUE.JS 3 + TYPESCRIPT
          </n-tag>
          <n-tag
            type="error"
            size="small"
            round
          >
            SUPABASE
          </n-tag>
        </n-space>
      </div>
    </n-gi>

    <n-gi
      v-motion-slide-visible-once-bottom
      span="3 s:3 m:2"
    >
      <div class="card">
        <n-space justify="space-between">
          <NH3>WorldSkills</NH3>

          <n-button
            circle
            @click="openLink('https://en.worldskills.ru/wsrussia/about')"
          >
            <template #icon>
              <n-icon><ExternalLink /></n-icon>
            </template>
          </n-button>
        </n-space>

        <NText>Во время учебы в колледже принимал участие в чемпионате</NText>
        <n-ul>
          <n-li>
            компетенция
            <n-gradient-text type="success">"Web-дизайн"</n-gradient-text>
            - 3 место в регионе
          </n-li>
          <n-li>
            компетенция
            <n-gradient-text type="success">"Прототипирование"</n-gradient-text>
            - 1 место в регионе и участие в отборе на национальный финал
          </n-li>
          <n-li>в последующем принимал участие в качестве эксперта</n-li>
        </n-ul>

        <n-space
          class="mt-05"
          size="small"
        >
          <n-tag
            type="success"
            size="small"
            round
          >
            Web-дизайн
          </n-tag>
          <n-tag
            type="error"
            size="small"
            round
          >
            Прототипирование
          </n-tag>
        </n-space>
      </div>
    </n-gi>

    <n-gi
      v-motion-slide-visible-once-bottom
      span="3 s:3 m:1"
    >
      <div class="card">
        <n-space justify="space-between">
          <NH3>Московский хакатон</NH3>

          <n-button
            circle
            @click="openLink('https://leadersofdigital.ru/')"
          >
            <template #icon>
              <n-icon><ExternalLink /></n-icon>
            </template>
          </n-button>
        </n-space>

        <NText>
          Участвовал в командной работе в
          <n-gradient-text type="success">Московском хакатоне</n-gradient-text>
          , выполнили задачу по разработке ПО для построения оптимального экологичного маршрута на разных типах
          транспорта, в результате заняли первое место
        </NText>

        <n-space
          class="mt-05"
          size="small"
        >
          <n-tag
            type="success"
            size="small"
            round
          >
            VUE.JS 3 + TYPESCRIPT
          </n-tag>
          <n-tag
            type="error"
            size="small"
            round
          >
            OpenStreetMap
          </n-tag>
        </n-space>
      </div>
    </n-gi>
  </n-grid>
</template>
<script setup lang="ts">
  import { NSpace, NGrid, NGi, NText, NH3, NTag, NUl, NLi, NGradientText, NIcon, NButton } from 'naive-ui';
  import { ExternalLink } from '@vicons/tabler';

  const openLink = (link: string) => {
    window.open(link, '_blank');
  };
</script>
