<template>
  <n-grid
    :cols="1"
    item-responsive
    responsive="screen"
  >
    <n-gi v-motion-slide-visible-once-bottom>
      <div class="card">
        <NH1>Контакты</NH1>

        <NText>Буду рад обсудить вашу задачу, предложить оптимальное решение и принять участие в вашем проекте.</NText>
        <br />

        <n-space
          class="mt-05"
          size="small"
        >
          <n-tooltip
            trigger="hover"
            v-for="tag in contacts"
            :key="tag.id"
          >
            <template #trigger>
              <n-tag
                checkable
                @click="openLink(tag.url)"
                round
                :bordered="false"
                size="large"
                :type="tag.type"
              >
                {{ tag.label }}
                <template #icon>
                  <n-icon>
                    <span
                      v-if="tag.label === 'HeadHunter'"
                      v-html="tag.icon"
                    />
                    <component
                      v-else
                      :is="tag.icon"
                    />
                  </n-icon>
                </template>
              </n-tag>
            </template>
            {{ tag.tooltip + tag.url }}
          </n-tooltip>
        </n-space>
      </div>
    </n-gi>
  </n-grid>
</template>
<script setup lang="ts">
  import { NSpace, NGrid, NGi, NH1, NText, NIcon, NTag, NTooltip } from 'naive-ui';
  import { BrandGithub, BrandTelegram, BrandVk, BrandGmail } from '@vicons/tabler';
  import { ref } from 'vue';

  const contacts = ref<any>([
    { id: 1, label: 'TG', icon: BrandTelegram, url: 'https://t.me/sdvmxm', type: 'info', tooltip: 'Написать: ' },
    { id: 2, label: 'VK', icon: BrandVk, url: 'https://vk.com/sedovmax', type: 'info', tooltip: 'Написать: ' },
    { id: 3, label: 'Gmail', icon: BrandGmail, url: 'mailto:sdvmxm@gmail.com', type: 'error', tooltip: 'Почта: ' },
    {
      id: 4,
      label: 'GH',
      icon: BrandGithub,
      url: 'https://github.com/MaximSedov',
      type: 'default',
      tooltip: 'Проекты: ',
    },
    {
      id: 5,
      label: 'HeadHunter',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26px" height="26px" viewBox="0 0 26 26" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,0%,0.784314%);fill-opacity:1;" d="M 13 26 C 20.179688 26 26 20.179688 26 13 C 26 5.820312 20.179688 0 13 0 C 5.820312 0 0 5.820312 0 13 C 0 20.179688 5.820312 26 13 26 Z M 13 26 "/><path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 19.324219 11.082031 C 18.929688 10.675781 18.371094 10.457031 17.691406 10.457031 C 16.839844 10.457031 16.191406 10.800781 15.816406 11.445312 L 15.816406 8.242188 L 13.847656 8.242188 L 13.847656 17.019531 L 15.816406 17.019531 L 15.816406 13.933594 C 15.816406 13.214844 15.960938 12.753906 16.183594 12.484375 C 16.398438 12.210938 16.699219 12.109375 17.027344 12.109375 C 17.316406 12.109375 17.542969 12.199219 17.703125 12.367188 C 17.863281 12.539062 17.953125 12.804688 17.953125 13.171875 L 17.953125 17.011719 L 19.921875 17.011719 L 19.921875 12.785156 C 19.921875 12.066406 19.710938 11.480469 19.324219 11.082031 Z M 10.53125 10.457031 C 9.679688 10.457031 9.027344 10.800781 8.65625 11.445312 L 8.65625 8.242188 L 6.6875 8.242188 L 6.6875 17.019531 L 8.65625 17.019531 L 8.65625 13.933594 C 8.65625 13.214844 8.800781 12.753906 9.023438 12.484375 C 9.238281 12.210938 9.539062 12.109375 9.867188 12.109375 C 10.15625 12.109375 10.382812 12.199219 10.542969 12.367188 C 10.699219 12.539062 10.792969 12.804688 10.792969 13.171875 L 10.792969 17.011719 L 12.761719 17.011719 L 12.761719 12.785156 C 12.761719 12.066406 12.550781 11.480469 12.160156 11.074219 C 11.769531 10.667969 11.210938 10.453125 10.53125 10.453125 Z M 10.53125 10.457031 "/></g></svg>',
      url: 'https://hh.ru/resume/1320408aff08a292a60039ed1f6f514f307a6f',
      type: 'default',
      tooltip: 'Резюме: ',
    },
  ]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };
</script>
