<template>
  <n-grid
    :cols="1"
    item-responsive
    responsive="screen"
  >
    <n-gi v-motion-slide-visible-once-bottom>
      <div class="card">
        <NH1>Актуальный стэк</NH1>
        <n-space>
          <n-tag
            v-for="tag in actualTags"
            :key="tag.text"
            :type="tag.type"
            :size="tag.size"
            round
          >
            {{ tag.text }}
          </n-tag>
        </n-space>
        <NH3 class="mt-05">Технологии, с которыми работал</NH3>
        <n-space>
          <n-tag
            v-for="tag in otherTags"
            :key="tag.text"
            :type="tag.type"
            :size="tag.size"
            round
          >
            {{ tag.text }}
          </n-tag>
        </n-space>
        <NText></NText>
      </div>
    </n-gi>
  </n-grid>
</template>
<script setup lang="ts">
  import { NSpace, NGrid, NGi, NH1, NText, NH3, NTag } from 'naive-ui';
  import { ref } from 'vue';

  const actualTags = ref<any>([
    { type: 'success', text: 'HTML + CSS', size: 'medium' },
    { type: 'success', text: 'VUE.JS 3 + TYPESCRIPT', size: 'medium' },
    { type: 'success', text: 'VUE ROUTER', size: 'medium' },
    { type: 'warning', text: 'VITE', size: 'medium' },
    { type: 'warning', text: 'VUE USE', size: 'medium' },
    { type: 'warning', text: 'VUE I18N', size: 'medium' },
    { type: 'warning', text: 'VUELIDATE', size: 'medium' },
    { type: 'warning', text: 'FLOATING VUE', size: 'medium' },
    { type: 'warning', text: 'PINIA', size: 'medium' },
    { type: 'warning', text: 'PINIA COLADA', size: 'medium' },
    { type: 'success', text: 'NAIVE UI', size: 'medium' },
    { type: 'success', text: 'ICONIFY', size: 'medium' },
    { type: 'success', text: 'UNO CSS', size: 'medium' },
    { type: 'info', text: 'AXIOS', size: 'medium' },
    { type: 'info', text: 'DATE FNS', size: 'medium' },
    { type: 'info', text: 'LODASH', size: 'medium' },
    { type: 'info', text: 'CHART.JS', size: 'medium' },
    { type: 'info', text: 'TINYMCE', size: 'medium' },
    { type: 'error', text: 'APPWRITE', size: 'medium' },
    { type: 'info', text: 'DOCKER + GITLAB', size: 'medium' },
  ]);

  const otherTags = ref<any>([
    { type: 'info', text: 'IONIC VUE', size: 'medium' },
    { type: 'info', text: 'CAPACITOR', size: 'medium' },
    { type: 'info', text: 'CORDOVA', size: 'medium' },
    { type: 'info', text: 'ELECTRON', size: 'medium' },
    { type: 'info', text: 'ELEMENT UI', size: 'medium' },
    { type: 'error', text: 'FIREBASE', size: 'medium' },
    { type: 'error', text: 'SUPABASE', size: 'medium' },
    { type: 'success', text: 'SASS', size: 'medium' },
    { type: 'success', text: 'SCSS', size: 'medium' },
    { type: 'warning', text: 'VUEX', size: 'medium' },
    { type: 'info', text: 'SENTRY', size: 'medium' },
  ]);
</script>
