import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';

declare module 'vue-router' {
  interface RouteMeta {
    isActive?: boolean;
  }
}
export const ROUTES_NAME = {
  HOME: 'Home',
  NOT_FOUND: 'NotFound',
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ROUTES_NAME.HOME,
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES_NAME.NOT_FOUND,
    component: () => import('@/views/HomePage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export const pushAction = (name: (typeof ROUTES_NAME)[keyof typeof ROUTES_NAME], params?: Record<string, string>) => {
  router.push({
    name,
    params,
  });
};

export default router;
