<template>
  <n-grid
    :cols="2"
    :x-gap="12"
    :y-gap="12"
    item-responsive
    responsive="screen"
  >
    <n-gi
      v-motion-slide-visible-once-bottom
      span="2 s:2 m:1"
    >
      <div class="card">
        <NH1>Карьера</NH1>
        <n-timeline size="large">
          <n-timeline-item
            v-for="(item, index) in careerHistory"
            :key="index"
            :type="item.type"
            :line-type="item.lineType"
            :title="item.title"
            :content="item.content"
            :time="item.time"
          />
        </n-timeline>
      </div>
    </n-gi>

    <n-gi
      v-motion-slide-visible-once-bottom
      span="2 s:2 m:1"
    >
      <div class="card">
        <NH1>Образование</NH1>
        <n-timeline size="large">
          <n-timeline-item
            v-for="(item, index) in educationHistory"
            :key="index"
            :type="item.type"
            :line-type="item.lineType"
            :title="item.title"
            :content="item.content"
            :time="item.time"
          />
        </n-timeline>
      </div>
    </n-gi>
  </n-grid>
</template>
<script setup lang="ts">
  import { NGrid, NGi, NH1, NTimeline, NTimelineItem } from 'naive-ui';
  import type { TimelineItemProps } from 'naive-ui';
  import { ref } from 'vue';

  const careerHistory = ref<TimelineItemProps[]>([
    {
      type: 'success',
      lineType: 'default',
      title: 'Индивидуальное предпринимательство',
      content: 'Работа над IT проектом',
      time: 'Сентябрь 2023 — настоящее время',
    },
    {
      type: 'success',
      lineType: 'dashed',
      title: 'Intrakommuna',
      content: 'Frontend-разработчик',
      time: 'Февраль 2022 — настоящее время',
    },
    {
      lineType: 'dashed',
      title: 'Фриланс',
      content: 'Frontend-разработчик',
      time: 'Июнь 2019 — Декабрь 2021, 2 года 7 месяцев',
      type: undefined,
    },
    {
      title: 'Газпром добыча Ноябрьск',
      content: 'Техник, в рамках производственной практики',
      time: 'Апрель 2019 — Май 2019, 2 месяца',
      lineType: 'dashed',
      type: undefined,
    },
    {
      title: 'Администрация г. Ноябрьск',
      content: 'Техник-практикант',
      time: 'Ноябрь 2018 — Декабрь 2018, 2 месяца',
      lineType: 'default',
      type: undefined,
    },
  ]);

  const educationHistory = ref<TimelineItemProps[]>([
    {
      lineType: 'dashed',
      title: 'Тульский институт экономики и информатики, Тула',
      content: 'Прикладная информатика в экономике, Прикладная информатика (бакалавр прикладной информатики)',
      time: '2023',
    },
    {
      lineType: 'dashed',
      title: 'Воронежский институт высоких технологий, Воронеж',
      content: 'Прикладная информатика (бакалавр прикладной информатики)',
      time: '2023',
    },
    {
      title: 'НКПИИТ',
      content: 'Программирование в компьютерных системах',
      time: '2019',
    },
  ]);
</script>
